import * as React from 'react';
// import {Link} from 'gatsby';
import * as styles from './header.module.scss';


const Navigation = ({worksRef, headerRef, contactsRef, pricesRef}) => {
    const [checked, setChecked] = React.useState(false);

    const items = [
        {name: 'Главная', ref: headerRef},
        {name: 'Цены', ref: pricesRef},
        {name: 'Наши работы', ref: worksRef},
        {name: 'Контакты', ref: contactsRef},
    ]
    const handleScroll = (ref) => {
        if (window !== "undefined") {
            window.scrollTo({
                top: ref.offsetTop,
                left: 0,
                behavior: "smooth",
            });
            setChecked(false)
        }
    };
    const burger = [...Array(3)].map((any, index) => <span key={index}/>)
    return (
        <nav className={styles.navigation}>
            <div className={styles.menuToggle}>
                <input type='checkbox' checked={checked} onChange={() => setChecked(!checked)}/>
                {burger}
                <div className={styles.menu}>
                    {items.map(({name, ref}) =>
                        <a key={name} className={styles.navigation__item} onClick={() => {
                            handleScroll(ref.current);
                        }}>{name}</a>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navigation;