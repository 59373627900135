import * as React from 'react';
import * as styles from './freeMeasure.module.scss';
import Container from "../../components/Container/Container";
import {testPhone} from "../../utils/utils";
import {sendData} from "../../service/api";

export const FreeMeasure = () => {
    const [value, setValue] = React.useState('')
    const disabled = value.length < 10;
    const handleChange = (event) => {
        testPhone(event) && setValue(event.target.value)
    }
    const handleClick = (event) => {
        event.preventDefault()
        sendData({phone: value}).then(
            (result) => {
                if (result) {
                    setValue('Спасибо, мы вам перезвоним')
                }
            }
        )
    }
    return (
        <div className={styles.freeMeasure}>
            <Container>

                <header className={'h2'}>БЕСПЛАТНЫЙ ЗАМЕР ВАШЕГО ОБЪЕКТА</header>
                {/*<div className={styles.blurBackground}>*/}

                <p className={'subHeader1'}>Оставьте заявку, и опытный специалист приедет на осмотр вашего
                    объекта,
                    ответит на все интересующие вас вопросы и проведёт необходимые замеры для точного расчета
                    стоимости
                    работ и материалов. Это бесплатно.
                </p>

                {/*</div>*/}
                <form>
                    <input className={styles.inputNumber} name='phone' maxLength={20} placeholder='Введите номер'
                           onChange={handleChange} value={value}
                    />
                    <button className={styles.buttonShort} onClick={handleClick} disabled={disabled}>ОК</button>
                </form>

            </Container>
        </div>
    )
}