import * as React from 'react';
import getContentTypeRoof from "../../helpers";
// @ts-ignore
import flex_tile from './gibkaya-cherepica.jpg'

const FlexTile = () => {
    const text = <>
        Когда другие кровельные материалы не справляются с поставленной задачей, <br/>
        в ход идет гибкая черепица. Обладает эластичными свойствами, укладывается на любой рельеф,
        включая луковичные и куполообразные конструкции под старину.<br/>
        С помощью битумной черепицы проводится реконструкция исторических кварталов и храмов.
        <br/><br/>
        Разумная экономия на коммунальных платежах и ремонте
        Монтаж мягкой кровли с утеплением из каменной ваты повышает уровень теплосбережения.<br/>
        Потери тепла через крыши становятся минимальными.<br/>
        Гарантия эксплуатации мягкой кровли сравнялась с минеральной благодаря модернизации технологии изготовления —
        50-60 лет.<br/>
        Это уже не рубероид, шифер или ондулин, который надо ремонтировать каждый год.
    </>
    return getContentTypeRoof({text, image: flex_tile, alt: 'Крыша из гибкой черепицы'});
};

export default FlexTile;