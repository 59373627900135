import * as React from 'react';
// @ts-ignore
import profnastil from './Tabs/Profnastil/profnastil.png'
// @ts-ignore
import * as styles from './typeRoof.module.scss';

interface IgetContentTypeRoofProps {
    text: React.ReactNode,
    image: string,
    alt: string
}


const getContentTypeRoof = (props: IgetContentTypeRoofProps) => {
    const {text, image, alt} = props;

    return (
        <article className={styles.article}>
            <figure>
                <img className={styles.img} src={image} alt={alt}/>
                <figcaption className={styles.figcaption}>{alt}</figcaption>
            </figure>
            <p>{text}</p>
        </article>
    );
};

export default getContentTypeRoof;