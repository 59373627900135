import * as React from 'react';
import * as styles from './header.module.scss';
import Logo from "../../images/logo.svg";
import Navigation from "./Navigation";
import Button from "../../components/Button/Button";
import Phone from "../../images/phone.svg";
import Container from "../../components/Container/Container";
import '../../styles/variables/_global.scss'
import {useState} from "react";
import ModalOrderCall from "../../components/ModalOrderCall/ModalOrderCall";
import MyContext from "../../context/context";


export const Header = ({worksRef, contactsRef, pricesRef}) => {
    const {setShowModal} = React.useContext(MyContext);
    const [showModalOrder, setShowModalOrder] = useState(false)
    const headerRef = React.useRef(null);

    const onClickHandler = () => {
        setShowModalOrder(!showModalOrder)
        setShowModal(!showModalOrder)
    }


    return (
        <div className={styles.wrap}>
            <Container>
                <div className={styles.header} ref={headerRef}>
                    <Logo className={styles.logo}/>
                    <Navigation worksRef={worksRef} contactsRef={contactsRef} pricesRef={pricesRef}
                                headerRef={headerRef}/>
                    <div className={styles.wrapPhoneButton}>
                        <div className={styles.phone}>

                            <a href="tel:+79873374252"><Phone/>&nbsp;&nbsp;8 987 337 42 52</a>

                        </div>
                        <Button text={"ЗАКАЗАТЬ ЗВОНОК"} size={'small'}
                                onClick={onClickHandler} className={styles.button}/>
                        {showModalOrder && <ModalOrderCall onCloseModal={onClickHandler}/>}
                    </div>
                </div>
            </Container>
        </div>
    )
}