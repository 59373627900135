import * as React from 'react';
import * as styles from './typeRoof.module.scss';
import Container from "../../components/Container/Container";
import Button from "../../components/Button/Button";
import cn from "classnames";
import Profnastil from "./Tabs/Profnastil/Profnastil";
import MetalTile from "./Tabs/MetalTile/MetalTile";
import SeamRoof from "./Tabs/SeamRoof/SeamRoof";
import FlexTile from "./Tabs/FlexTile/FlexTile";
import MyContext from "../../context/context";
import {useState} from "react";
import ModalOrderCall from "../../components/ModalOrderCall/ModalOrderCall";

enum TypesRoof {
    PROFNASTIL = "Профнастил",
    METAL_TILE = "Металлочерепица",
    FOLDED_ROOF = "Фальцевая кровля",
    FLEX_ROOF = "Гибкая черепица"
}

export const TypeRoof = () => {
    const [value, setValue] = React.useState(TypesRoof.PROFNASTIL)
    const {setShowModal} = React.useContext(MyContext);
    const [showModalOrder, setShowModalOrder] = useState(false)
    const [showMore, setShowMore] = useState(false);
    
    const onClickHandler = () => {
        setShowModalOrder(!showModalOrder)
        setShowModal(!showModalOrder)
    }

    const handleClick = (event) => {
        console.log(event.target.value)
        setValue(event.target.value)
    }

    return (
        <div>
            <Container>
                <header className={'h2'}>Выберите нужный тип кровли</header>
                <p className={'subHeader1'}>В зависимости от различной конструкции крыши
                </p>

                <div className={styles.wrapButtons}>
                    {Object.values(TypesRoof).map((item, index) =>
                        <Button key={index} text={item} value={item}
                                className={cn(styles.button, value === item && styles.active)}
                                onClick={handleClick}/>
                    )}
                </div>
                {
                    {
                        [TypesRoof.PROFNASTIL]: <Profnastil/>,
                        [TypesRoof.METAL_TILE]: <MetalTile/>,
                        [TypesRoof.FOLDED_ROOF]: <SeamRoof/>,
                        [TypesRoof.FLEX_ROOF]: <FlexTile/>

                    }[value]
                }
                <div>
                    <h2>Вопросы по типу кровли? Консультация бесплатно!</h2>
                    <Button text={'ЗАКАЗАТЬ ЗВОНОК'} className={styles.button} onClick={onClickHandler}/>
                </div>
                {showModalOrder && <ModalOrderCall onCloseModal={onClickHandler}/>}
            </Container>
        </div>
    )
}