import * as React from 'react';
import getContentTypeRoof from "../../helpers";
import profnastil from './profnastil.jpg'

const Profnastil = () => {
    const text = <>
        Кровельный материал с продольным рифлением.<br/>
        Изготовлен из металлической оцинкованной жести толщиной 0.4-1мм с полимерным покрытием. Используется для кровли
        жилых и нежилых зданий и сооружений.<br/>
        <br/><br/>
        Виды профнастила:<br/>
        1. Кровельный<br/>
        2. Настенный(для кровли фронтонов, стен, заборов)<br/>
        <br/><br/>
        Преимущества :<br/>
        1. Большая длина продольного листа и отсутствие попёрчного рифления даёт возможность достаточно герметичности
        кровли при наклоне ската менее 14 градусов<br/>
        2. Более быстрый монтаж<br/>
        <br/><br/>
        Недостатки:<br/>
        Ограниченный дизайн внешнего вида
    </>
    return getContentTypeRoof({text, image: profnastil, alt: 'Листы профнастила'});
};

export default Profnastil;