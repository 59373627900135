import * as React from 'react';
import * as styles from '../quiz.module.scss';
import dvuskatnaya from './dvuskatnaya.svg'
import mansardnaya from './mansardnaya.svg'
import odnoskatnaya from './odnoskatnaya.svg'
import ploskaya from './ploskaya.svg'
import poluvalmovaya from './poluvalmovaya.svg'
import valmovaya from './valmovaya.svg'
import cn from 'classnames'
import {GetAnswers} from "../helpers";


const Step3 = (props) => {
    const arrayAnswers = [
        {img: odnoskatnaya, title: "Односкатная"},
        {img: dvuskatnaya, title: "Двускатная"},
        {img: valmovaya, title: "Вальмовая"},
        {img: poluvalmovaya, title: "Полувальмовая"},
        {img: ploskaya, title: "Плоская"},
        {img: mansardnaya, title: "Мансардная"},
    ]
    const header = 'Выберите тип Вашей крыши'

    return GetAnswers(header, arrayAnswers, props)
    
};

export default Step3;