import * as React from "react";
import * as styles from "./formOrder.module.scss";
import TextField from "../TextField/TextField";
import TogglePolicy from "../TogglePolicy/TogglePolicy";
import Button from "../Button/Button";
import {SubmitHandler, useForm} from "react-hook-form";
import {Color} from "../../enums";
import {sendData} from "../../service/api";


type Inputs = {
    name: string,
    phone: string,
    message: string,
};
type FormOrder = {
    color?: Color;
    setThankYouMessage: any //TODO назначит тип
}
const FormOrder = ({color = Color.DEFAULT, setThankYouMessage}: FormOrder) => {
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = data => {
        sendData(data).then(
            (result) => {
                if (result) {
                    console.log(result)
                    setThankYouMessage(true);
                    reset();
                    setValue("message","Спасибо! Мы вам перезвоним")
                }
            }
        )
    };
    const [togglePolicy, setTogglePolicy] = React.useState(true)
    const changeTogglePolicy = () => {
        setTogglePolicy(!togglePolicy)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>

            <TextField placeholder="Введите имя" {...register("name")} color={color}/>

            {errors.phone && <span className={styles[color]}>Это поле обязательно</span>}
            <TextField
                color={color}
                error={!!errors.phone}
                placeholder={"Введите телефон"} {...register("phone", {required: true})} />

            <TextField color={color} maxLength={255} className={styles.area}
                       placeholder="Введите сообщение" {...register("message",)} multiline rows={10}/>

            <TogglePolicy color={color} checked={togglePolicy} onChangeToggle={changeTogglePolicy}
            />

            <Button color={color} className={styles.button} size='medium' text="ОТПРАВИТЬ" type="submit"/>
        </form>
    );
};

export default FormOrder;
