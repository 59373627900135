import * as React from 'react';
import mpcspikvds1 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_1.jpg'
import mpcspikvds2 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_2.jpg'
import mpcspikvds3 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_3.jpg'
import mpcspikvds4 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_4.jpg'
import mpcspikvds5 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_5.jpg'
import mpcspikvds6 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_6.jpg'
import mpcspikvds7 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_7.jpg'
import mpcspikvds8 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_8.jpg'
import mpcspikvds9 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_9.jpg'
import mpcspikvds10 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_10.jpg'
import mpcspikvds11 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_11.jpg'
import mpcspikvds12 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_12.jpg'
import mpcspikvds13 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_13.jpg'
import mpcspikvds14 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_14.jpg'
import mpcspikvds15 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_15.jpg'
import mpcspikvds16 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_16.jpg'
import mpcspikvds17 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_17.jpg'
import mpcspikvds18 from './morskaya-pamyatnaya-chasovnya-sv-prav-ioanna-kronshtadtskogo-v-derevne-staraya_18.jpg'
import GetWork from "../helpers";

const Peterburg = () => {
    const workData =
        {
            header: <>
                Морская памятная часовня
                св. прав. Иоанна Кронштадтского <br/>
                в деревне Старая
                Ленинградская область</>,
            description: <>Виды работы:<br/>
                - Изготовление и монтаж каркаса 8-ми металлических радиальных козырьков из металлической трубы
                20х20 <br/>
                - Изготовление и сварка из квадратной трубы 50х100 металлического креста с хвостовиком для
                крепления.<br/>
                - Изготовление подкрестного шара и подкрестной тумбы из нержавеющего листа с покрытием нитрит
                титана<br/>
                - Монтаж покрытия креста <br/>
                - Монтаж кровли козырьков <br/>
                - Монтаж кровли ротонды <br/>
                - Монтаж кровли у основания колон <br/><br/>
                Кровельное покрытие: нержавеющая сталь толщиной 0,5мм с покрытием из нитрит титана. <br/>
                Вид кровли: квадратная чешуя</>,
            images: [mpcspikvds1, mpcspikvds2, mpcspikvds3, mpcspikvds4
                , mpcspikvds5, mpcspikvds6, mpcspikvds7, mpcspikvds8,
                mpcspikvds9, mpcspikvds10, mpcspikvds11, mpcspikvds12,
                mpcspikvds13, mpcspikvds14, mpcspikvds15, mpcspikvds16
                , mpcspikvds17, mpcspikvds18]
        }

    return GetWork(workData)

};

export default Peterburg;