import * as React from 'react';
import * as styles from './bookDiscoint.module.scss';
import Container from "../../components/Container/Container";
import {SubmitHandler, useForm} from "react-hook-form";

import {Color} from "../../enums";
import FormOrder from "../../components/FormOrder/FormOrder";
import {useState} from "react";


type Inputs = {
    name: string,
    phone: string,
    message: string
};

export const BookDiscoint = () => {
    const [thankYouMessage, setThankYouMessage] = useState(false)

    return (
        <section className={styles.bookDiscount}>
            <div className={styles.wrapHeader}>
                <Container>
                    <h2>Забронируйте скидку на кровельные работы</h2>
                    <p className={'subHeader1'}> Наш специалист свяжется с Вами в ближайшее время</p>
                </Container>
            </div>

            <FormOrder color={Color.SECONDARY} setThankYouMessage={setThankYouMessage}/>
            {/*<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>*/}

            {/*    <TextField color={Color.SECONDARY}*/}
            {/*               placeholder="Введите имя"*/}
            {/*        // error={false}*/}
            {/*               {...register("name")} />*/}


            {/*    <TextField color={Color.SECONDARY}*/}
            {/*               placeholder={"Введите телефон"}*/}
            {/*               {...register("phone", {required: true})}*/}
            {/*        // error={!!errors.phone}*/}
            {/*               helperText={'Поле телефон обязательно'}*/}
            {/*    />*/}


            {/*    <TextField color={Color.SECONDARY}*/}
            {/*               multiline*/}
            {/*               maxLength={255}*/}
            {/*               className={styles.input}*/}
            {/*               placeholder="Введите сообщение"*/}
            {/*               {...register("message",)} />*/}


            {/*    <Button className={styles.button} text="ОТПРАВИТЬ" type="submit"/>*/}
            {/*</form>*/}
        </section>
    )
        ;
}