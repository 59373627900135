import * as React from 'react';
import * as styles from '../quiz.module.scss';

const Step6 = () => {
    return (
        <div className={styles.stepWrap6}>
            <div className={styles.header}>Спасибо!</div>
            <div className={styles.subHeader}>Благодарим за Ваши ответы! <br/>Наши специалисты уже начали расчет Вашей
                идеальной крыши. <br/>Мы свяжемся с вами для уточнения деталей, стоимости и сроков работы.
            </div>
        </div>
    );
};

export default Step6;