import * as React from "react";
import * as styles from "./togglePolicy.module.scss";
import {Color} from "../../enums";
import cn from "classnames";

type TogglePolicy = {
    color: Color,
    checked: boolean,
    onChangeToggle: () => void
}

const TogglePolicy = React.forwardRef(({color, checked, onChangeToggle}: TogglePolicy, ref) => {
            return (
                <div className={styles.togglePolicy}>
                    <p className={cn(styles.textPolicy, styles[color])}>
                        Даю согласие на обработку моих персональных данных в соответствии с Правилами
                    </p>
                    <label className={styles.switcher}>
                        <input type="checkbox" defaultChecked={checked} className={styles.input}/>
                        <span className={cn(styles.slider, styles[color])} onClick={onChangeToggle}/>
                    </label>
                </div>

            );
        }
    )
;
export default TogglePolicy;