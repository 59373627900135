import * as React from 'react';
import * as styles from './quiz.module.scss';
import MyContext from "../../../context/context";
import Button from "../../../components/Button/Button";
import arrow_back from "./arrow_back.svg";
import Step1 from "./step1/step1";
import Step2 from "./step2/step2";
import Step3 from "./step3/step3";
import Step4 from "./step4/step4";
import Step5 from "./step5/step5";
import Step6 from "./step6/step6";
// import {CSSTransition} from 'react-transition-group'
import {sendData} from "../../../service/api";
import {testPhone} from "../../../utils/utils";
import WrapModal from "../../../components/WrapModal/WrapModal";


export const Quiz = ({onCloseQuiz}) => {

    const {step, setStep} = React.useContext(MyContext);
    const {answers, setAnswers} = React.useContext(MyContext);

    const changeHandlerChild = (event) => {
        if (window !== "undefined") {
            event.stopPropagation();
        }
    }
    const handleChangeNextStep = () => {
        setStep((prev) => prev + 1)
    }
    const handleChangeBackStep = () => {
        setStep((prev) => prev - 1)
    }
    const submitClick = () => {

        sendData({phone: answers.phone})
            .then(
                (result) => {
                    if (result) {
                        setStep(6)
                    }
                }
            )

    }

    const handleChangeAnswer = (event) => {
        switch (event.target.getAttribute('name')) {
            case 'phone':
                testPhone(event) && setAnswers({...answers, phone: event.target.value})
                break;
            case 'another':
                setAnswers({
                    ...answers,
                    [`step${step}`]: event.target.closest('label').querySelector('input[name]').value,
                    [`step${step}Addition`]: event.target.value
                })
                break;
            default:
                setAnswers({...answers, [`step${step}`]: event.target.value, step4Addition: ''})
                event.target.value !== 'Другая' && setStep(step + 1)
        }

    }
    let percentProgress;
    const currentStep = () => {
        percentProgress = step * 20;

        switch (step) {
            case 1:
                return <Step1 handleChangeAnswer={handleChangeAnswer}/>
            case 2:
                return <Step2 handleChangeAnswer={handleChangeAnswer}/>
            case 3:
                return <Step3 handleChangeAnswer={handleChangeAnswer}/>
            case 4:
                return <Step4 handleChangeAnswer={handleChangeAnswer} answers={answers}/>
            case 5:
                return <Step5 handleChangeAnswer={handleChangeAnswer} onSubmitClick={submitClick} answers={answers}/>
            case 6:
                return <Step6/>
            default:
                return <Step1 handleChangeAnswer={handleChangeAnswer}/>
        }
    }

    return (
        <WrapModal onClick={onCloseQuiz}>
            {/*<CSSTransition in={showModal} timeout={1300} classNames="my-node">//TODO*/}
            <div className={styles.quiz} onClick={changeHandlerChild} role="presentation">
                <div className={styles.closeModal} onClick={onCloseQuiz}/>
                {currentStep()}

                {step < 6 && <div className={styles.control}>
                    <div className={styles.progressWrap}>
                        <div className={styles.progressText}>
                            Готово:<span> {percentProgress}%</span></div>
                        <div className={styles.progressBar}>
                            <span className={styles.progressBarFill} style={{width: `${percentProgress}%`}}/>
                        </div>
                    </div>
                    {step > 1 && <Button className={styles.buttonBack} onClick={handleChangeBackStep}
                                         text={<img src={arrow_back} alt={'назад'}/>}/>}
                    {step < 5 &&
                    <Button className={styles.buttonNext} text={"Вперед"} onClick={handleChangeNextStep}/>}
                </div>}

            </div>
            {/*</CSSTransition>*/}
        </WrapModal>

    )
}