import * as React from 'react';
import * as styles from '../quiz.module.scss';
import Button from "../../../../components/Button/Button";


const Step5 = (props) => {
    const {handleChangeAnswer, onSubmitClick, answers} = props
    const disabled = answers.phone ? answers.phone.length < 10 : true
    return (
        <div className={styles.stepWrap5}>
            <div className={styles.header}>Отлично! Последний шаг!</div>
            <div className={styles.subHeader}>Оставьте Ваш контакт и получите расчет + скидку</div>

            <div className={styles.complete}/>

            <input required='true' name='phone' type='tel' className={styles.inputQuiz} placeholder='введите телефон'
                   onChange={handleChangeAnswer} value={answers.phone || ''}/>

            <Button text='ОТПРАВИТЬ ЗАЯВКУ' className={styles.step5Button} onClick={onSubmitClick}
                    disabled={disabled}/>
        </div>
    );
};

export default Step5;