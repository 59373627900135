import * as styles from "./quiz.module.scss";
import React from "react";
import MyContext from "../../../context/context";
import cn from 'classnames'

export const GetAnswers = (header, arrayAnswers, props) => {
    const {handleChangeAnswer} = props
    const {step} = React.useContext(MyContext);
    const {answers} = React.useContext(MyContext);

    return <>
        <div className={styles.header}>{header}</div>
        <div className={cn(styles.stepWrap, styles[`stepWrap${step}`])}>
            {arrayAnswers.map((item, index) =>
                (
                    <label className={styles.itemWrap} alt={item.title} key={index}>
                        <input type='radio' id={`check${index}`} name="square" value={item.title}
                               checked={item.title === answers[`step${step}`]}
                               onChange={handleChangeAnswer}/>
                        {item.img && <img className={styles.itemImg} src={item.img}
                                          alt={`выбрать ${item.title}`}/>}
                        <div className={styles.itemTitle}>{item.elem || item.title}</div>
                    </label>
                )
            )}
        </div>
    </>
}
