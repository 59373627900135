import * as React from 'react';
import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import * as styles from "./works.module.scss";

const GetWork = (props) => {
    const {header, description, images} = props
    const regexAlt = /([\w-]+)-{1}.+\.jpg/;
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const slides = images.map(
        (image) => <img alt={image.match(regexAlt)[1]} src={image}/>
    );

    return (
        <div className={styles.workWrap}>
            <article>
                <h3 className={styles.header}>{header}</h3>
                <p className={styles.description}>{description}</p>
            </article>

            <Swiper
                loop
                modules={[Navigation]}
                className={styles.slider}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                    disabledClass: styles.swiperButtonDisabled
                }}
                slidesPerView={1}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
            >
                {slides.map((slideContent, index) => (
                    <SwiperSlide key={index}>
                        {slideContent}
                    </SwiperSlide>
                ))}
                <div ref={navigationPrevRef} className={styles.swiperButtonPrev}/>
                <div ref={navigationNextRef} className={styles.swiperButtonNext}/>
            </Swiper>
        </div>
    );
};

export default GetWork;