import * as React from 'react';
import * as styles from './footer.module.scss'
import phone from './phone.svg'
import email from './E-mail.svg'
import adress from './Adress.svg'
import Container from "../../components/Container/Container";

export const Footer = ({contactsRef}) => {
    const dataFooter = [
        {
            img: phone, text: <>Телефон <br/>
                8 987 337-42-52</>
        },
        {
            img: email, text: <>Email<br/>
                krovsaratov@gmail.com</>
        },
        {
            img: adress, text: <>Адрес<br/>
                г. Саратов и Саратовская область</>
        },
    ]
    return (
        <div className={styles.footer} ref={contactsRef}>
            <Container className={styles.container}>

                {dataFooter.map(({img, text}, index) =>
                    <div className={styles.itemWrap} key={index}>
                        <img src={img}/>
                        <p>{text}</p>
                    </div>
                )}

            </Container>
            <p className={styles.policy}> © {new Date().getFullYear()} - КРОВСАР | ВСЕ ПРАВА ЗАЩИЩЕНЫ -</p>
        </div>
    );
};

