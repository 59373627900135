import * as React from 'react';
import getContentTypeRoof from "../../helpers";
// @ts-ignore
import seam_roof from './falcevaya-krovlya.jpg'

const SeamRoof = () => {
    const text = <>
        За основу названия взято соедение листов металла с помощью предварительно заготовленного фальца.<br/>
        <br/>
        Материал<br/>
        1. железный оцинкованный лист толщиной 0.4 - 0.7 мм<br/>
        2. медная жесть толщиной 0.6-0.8мм<br/>
        3. нержвеющая сталь толщиной 0.5 мм(возможно с покрытием нитрид титана)<br/>
        4. алюминий толщиной 0.5мм<br/>
        5. титано-цинк толщиной 0.5мм<br/>
        <br/>
        Приемущества<br/>
        1. Наибольшая гермитичность шва<br/>
        2. Возможность кровли сложных форм дизайна крыш (обсиды , башни, главки храмов)<br/>
        3. Долговечность<br/>
        <br/>
        Недостатки<br/>
        1. Необходимость сплошной обрешётки по всей площади кровли, дополнительная шума изоляция от дождя<br/>
        2. Дороговизна монтажа<br/>
        3. Более сложный ремонт при повреждениях<br/>
    </>
    return getContentTypeRoof({text, image: seam_roof, alt: 'Крыша из фальцевой кровли'});
};


export default SeamRoof;