import * as React from 'react';
import * as styles from './calculatePrice.module.scss';
import Button from "../../components/Button/Button";
import {Quiz} from "./Quiz/Quiz";
import MyContext from "../../context/context";
// import {useContext} from 'react';


export const CalculatePrice = () => {
    const {showModal, setShowModal} = React.useContext(MyContext);
    const [showQuiz, setShowQuiz] = React.useState(false)
    // console.log(showModal)
    const changeHandler = (event) => {
        // console.log(event)
        // event.preventDefault()
        setShowQuiz(!showQuiz)
        setShowModal(!showModal)

    }

    return (
        <div className={styles.calculatePrice}>
            {showQuiz && <Quiz onCloseQuiz={changeHandler}/>}
            <h2 className={'h2'}>Рассчитать стоимость кровельных работ прямо сейчас</h2>
            <Button text={'Рассчитать'} className={styles.calculatePrice__button} onClick={changeHandler}/>
        </div>
    )
}