import * as React from 'react';
import * as styles from "./modalOrderCall.module.scss";
import WrapModal from "../WrapModal/WrapModal";
import cn from "classnames";
import FormOrder from "../FormOrder/FormOrder";
import {useState} from "react";
import ThankYouMessage from "../ThankYouMessage/ThankYouMessage";


const ModalOrderCall = ({onCloseModal}) => {
    const [thankYouMessage, setThankYouMessage] = useState(false)
    const changeHandlerChild = (event) => {
        event.stopPropagation();
    }
    console.log(thankYouMessage)
    // if (thankYouMessage) {
    //     return <ThankYouMessage onCloseModal={onCloseModal}/>
    // }
    return (
        <WrapModal onClick={onCloseModal}>

            <div className={styles.modalOrderCall} onClick={changeHandlerChild}>
                <div className={styles.closeModal} onClick={onCloseModal}/>
                {thankYouMessage ? <ThankYouMessage onCloseModal={onCloseModal}/> :
                    <>
                        <div className={cn('h3', styles.title)}>Оставьте заявку прямо сейчас</div>
                        <div className={cn('subHeader2', styles.subTitle)}>Мы перезвоним вам в ближайшее время
                        </div>
                        <FormOrder setThankYouMessage={setThankYouMessage}/>
                    </>}
            </div>

        </WrapModal>
    );
};

export default ModalOrderCall;