import * as React from 'react';
import * as styles from './works.module.scss';
import Container from "../../components/Container/Container";
import Button from "../../components/Button/Button";

import 'swiper/scss';
import Utali from "./utali/utali";
import Kaweli from "./kaweli/kaweli";
import Celinniy from "./celinniy/celinniy";
import Peterburg from "./peterburg/peterburg";
import Abkhazia from "./abkhazia/abkhazia";

export const Works = ({worksRef}) => {
    const [showAdditionalWorks, setShowAdditionalWorks] = React.useState(false)

    const handleClick = () => {
        setShowAdditionalWorks(!showAdditionalWorks)
    }
    const textButton = showAdditionalWorks ? 'Скрыть доп. работы' : 'Посмотреть ещё'
    return (
        <section ref={worksRef}>
            <Container>
                <h2>Работы, которыми мы гордимся</h2>
                <p className='subHeader1'>Нам доверяют строить церкви</p>
                <div className={styles.worksWrap}>
                    <Utali/>
                    <Kaweli/>
                    <Celinniy/>
                    {showAdditionalWorks && <Peterburg/>}
                    {showAdditionalWorks && <Abkhazia/>}
                </div>

                <Button text={textButton} className={styles.button} onClick={handleClick}/>
            </Container>
        </section>
    )
}