// extracted by mini-css-extract-plugin
export var quiz = "quiz-module--quiz--E1069";
export var stepWrap = "quiz-module--stepWrap--HMlKN";
export var itemWrap = "quiz-module--itemWrap--DwcFx";
export var itemImg = "quiz-module--itemImg--XjHqU";
export var itemTitle = "quiz-module--itemTitle--UsTj-";
export var stepWrap2 = "quiz-module--stepWrap2--4M17W";
export var stepWrap3 = "quiz-module--stepWrap3--7w1I3";
export var stepWrap4 = "quiz-module--stepWrap4--9wEoL";
export var stepWrap5 = "quiz-module--stepWrap5--LzYTK";
export var header = "quiz-module--header--qGIBX";
export var complete = "quiz-module--complete--0noOD";
export var inputQuiz = "quiz-module--inputQuiz--nhZ44";
export var stepWrap6 = "quiz-module--stepWrap6--oeeT0";
export var subHeader1 = "quiz-module--subHeader1--0qAaL";
export var step5Button = "quiz-module--step5Button--dmDAq";
export var control = "quiz-module--control--4DeZL";
export var buttonBack = "quiz-module--buttonBack--wKBby";
export var buttonNext = "quiz-module--buttonNext--LoeTB";
export var progressWrap = "quiz-module--progressWrap--Kbij3";
export var progressText = "quiz-module--progressText--pz8Oc";
export var progressBar = "quiz-module--progressBar--fDkaw";
export var progressBarFill = "quiz-module--progressBarFill--x1oCM";
export var closeModal = "quiz-module--closeModal--9k5Qe";