import * as React from 'react';
import hibmumpgs from './hram-ikony-bozhiej-materi-utoli-moya-pechali-g-saratov-obshchij-vid.jpg'
import vpsmvb from './vosmigrannoj-piramidy-s-mednoj-zhestyu-v-blizi.jpg'
import mvpsmzspk from './montazh-vosmigrannoj-piramidy-s-mednoj-zhestyu-s-pomoshchyu-krana.jpg'
import GetWork from "../helpers";

const Utali = () => {
    const workData =
        {
            header: <>Храм иконы Божией Матери «Утоли моя печали» Саратов</>,
            description: <>Примерная площадь: 800м2<br/>
                тип кровли: - зодческая + фальцевая<br/>
                <br/><br/>
                Проведённые виды работы:<br/>
                - демонтаж старой железной жестяной кровли, замена на медную<br/>
                - ремонт - частичная замена стропил и полной обрешётки<br/>
                - замена старой деревянной восьмигранной пирамиды на новую из&nbsp;металлического каркаса<br/>
                - Реставрация 120 кокошников<br/>
                <br/><br/>
                *количество материала: Было использованно 4000кг медной <br/>жести , толщиной 0.6мм
                <br/><br/>
                С благословения настоятеля храма Игумен НЕКТАРИЙ (Морозов)</>,
            images: [vpsmvb, hibmumpgs, mvpsmzspk]
        }

    return GetWork(workData)

};

export default Utali;