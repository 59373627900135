import * as React from 'react';
import * as styles from './prices.module.scss';
import Container from "../../components/Container/Container";
import cn from "classnames";
import falcevaya_krovlya from './falcevaya-krovlya.jpg'
import gibkayaCherepica from './gibkaya-cherepica.jpg'
import metallocherepica from './metallocherepica.jpg'
import profnsatil from './profnsatil.jpg'
import Button from "../../components/Button/Button";
import MyContext from "../../context/context";
import {useState} from "react";
import ModalOrderCall from "../../components/ModalOrderCall/ModalOrderCall";

interface Iprices {
    img: any;
    name: string;
    price: number
}

export const Prices = ({pricesRef}) => {
    const prices: Iprices[] = [
        {img: profnsatil, name: 'Профнастил', price: 250},
        {img: metallocherepica, name: 'Металлочерепица', price: 300},
        {img: falcevaya_krovlya, name: 'Фальцевая кровля', price: 400},
        {img: gibkayaCherepica, name: 'Гибкая черепица', price: 250},
    ]
    const {setShowModal} = React.useContext(MyContext);
    const [showModalOrder, setShowModalOrder] = useState(false)

    const onClickHandler = () => {
        setShowModalOrder(!showModalOrder)
        setShowModal(!showModalOrder)
    }

    return (
        <section className={styles.prices} ref={pricesRef}>
            <Container>

                <h2>Цены на кровельные работы “под ключ”</h2>
                <p className={cn('subHeader1', styles.subHeader)}>Без учета строительных материалов </p>
                <div className={styles.wrapItems} onClick={onClickHandler}>
                    {prices.map((item, index) =>
                        <div className={styles.item} key={index}>
                            <img src={item.img} alt={item.name}/>
                            <p>{item.name}</p>
                            <hr/>
                            <span>{item.price} руб/м2</span>
                            <Button text="Заказать" className={styles.button}/>
                        </div>)}
                </div>
                {showModalOrder && <ModalOrderCall onCloseModal={onClickHandler}/>} {/*//TODO DRY*/}

            </Container>
        </section>
    )
}