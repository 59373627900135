import * as React from "react";
import '../styles/index.scss';
import MyContext from "../context/context";
import {Header} from "../Sections/Header/Header";
import {MainBanner} from "../Sections/MainBanner/MainBanner";
import {Advantages} from "../Sections/Advantages/Advantages";
import {CalculatePrice} from "../Sections/CalculatePrice/CalculatePrice";
import {Works} from "../Sections/Works/Works";
import {FreeMeasure} from "../Sections/FreeMeasure/FreeMeasure";
import {TypeRoof} from "../Sections/TypeRoof/TypeRoof";
import {Prices} from "../Sections/Prices/Prices";
import {StagesWork} from "../Sections/StagesWork/StagesWork";
import {BookDiscoint} from "../Sections/BookDiscount/BookDiscoint";
import {Footer} from "../Sections/Footer/Footer";
import Helmet from 'react-helmet'

const IndexPage = () => {
    const [showModal, setShowModal] = React.useState(false);
    const [step, setStep] = React.useState(1);
    const [answers, setAnswers] = React.useState({});
    const jsonStringifyAnswer = JSON.stringify(answers)
    // console.log(jsonStringifyAnswer)

    const value = React.useMemo(
        () => ({showModal, setShowModal, step, setStep, answers, setAnswers}),
        [showModal, step, jsonStringifyAnswer]
    );
    // const sectionsRef = React.useRef([]);
    // useEffect(() => {
    //     console.log(sectionsRef)
    // }, [])

    const worksRef = React.useRef(null);
    const contactsRef = React.useRef(null);
    const pricesRef = React.useRef(null);

    return (
        <MyContext.Provider value={value}>
            <Helmet
                bodyAttributes={{
                    class: `${showModal && 'isOpenModal'}`
                }}
            />
            <title>Кровельные работы</title>
            <Header
                worksRef={worksRef}
                contactsRef={contactsRef}
                pricesRef={pricesRef}
            />
            <main>
                <MainBanner/>
                <Advantages/>
                <CalculatePrice/>
                <Works worksRef={worksRef} />
                <FreeMeasure/>
                <TypeRoof/>
                <Prices pricesRef={pricesRef}/>
                <StagesWork/>
                <BookDiscoint/>
            </main>
            <Footer contactsRef={contactsRef}/>
        </MyContext.Provider>
    )
}

export default IndexPage
