import * as React from 'react';
import getContentTypeRoof from "../../helpers";
// @ts-ignore
import metal_tile from './metallocherepica.jpg'

const MetalTile = () => {
    const text = <>
        Это кровельный материал с продольным и поперёчным рифлением изготовлен из металлической оцинкованной жести с
        полимерным покрытием, либо из меди, толщиной 0.4-0.5мм , используется для кровли жилых и нежилых зданий и
        сооружений.<br/><br/>
        Применяется для кровли с уклоном 14 градусов и больше , не рекомендуется применять на кровле имеющей
        малый уклон.<br/>
        <br/><br/>
        Преимущества:<br/>
        1. Кровля имеет дизайн под натуральную черепицу<br/>
        2. Металлочерепица изготовлена из медной жести, служит несколько десятков лет при бережном обращении<br/>
    </>
    return getContentTypeRoof({text, image: metal_tile, alt: 'Крыша из металлочерпицы'});
};

export default MetalTile;