import * as React from 'react';
import * as styles from '../quiz.module.scss';
import {GetAnswers} from "../helpers";

const Step4 = (props) => {
    const {handleChangeAnswer, answers} = props;

    const arrayAnswers = [
        {title: "70-150 м2"},
        {title: "150-200 м2"},
        {title: "200-250 м2"},
        {title: "250-300 м2"},
        {title: "более 300 м2"},
        {title: "Затрудняюсь ответить"},
        {
            title: "Другая",
            elem: <><input type="text" className={styles.inputQuiz} placeholder='Другая' name='another'
                           value={answers.step4Addition || ''}
                           onChange={handleChangeAnswer}/>&nbsp;м2</>
        },
    ]
    const header = 'Выберите площадь Вашей крыши или площадь дома'

    return GetAnswers(header, arrayAnswers, props)
};

export default Step4;