import * as React from 'react';
import * as styles from './advantages.module.scss';
import Container from "../../components/Container/Container";
import Cards from "../../images/cards.svg";
import PenAndKey from "../../images/pen_and_key.svg"
import Roof from "../../images/roof.svg"
import Calendar from "../../images/calendar.svg"
import Shield from "../../images/shield.svg"
import RoofAndKey from "../../images/roof_and_key.svg"

export const Advantages = () => {
    const iconsWithSubscription = [
        {icon: <Cards/>, text: <>Безналичная или <br/>наличная оплата</>},
        {icon: <PenAndKey/>, text: "Опыт работы 14 лет"},
        {icon: <Roof/>, text: <>Материалы от <br/>производителя</>},
        {icon: <Calendar/>, text: "Соблюдение сроков"},
        {icon: <Shield/>, text: <>Гарантия на монтаж <br/>10 лет</>},
        {icon: <RoofAndKey/>, text: "Строим под ключ"},
    ]

    return (
        <Container>
            <header className={'h2'}>Преимущества</header>
            <div className={styles.advantages}>
                {iconsWithSubscription.map((item, index) => (
                        <div className={styles.advantages__item} key={index}>
                            <span>{item.icon} </span>
                            <p>{item.text}</p>
                        </div>
                    )
                )}
            </div>
        </Container>
    )
}