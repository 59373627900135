import * as React from 'react';
import hrpsr1 from './hram-prepodobnogo-sergiya-radonezhskogo_1.jpg'
import hrpsr2 from './hram-prepodobnogo-sergiya-radonezhskogo_2.jpg'
import GetWork from "../helpers";

const Kaweli = () => {
    const workData =
        {
            header: <>Храм Преподобного Сергия Радонежского<br/>
                с.Кошели Воскресенского р-на</>,
            description: <>Виды работы:<br/>
                - Монтаж стропил и обрешетки основной кровли<br/>
                - Монтаж фермы восьмигранной пирамиды из деревянного бруса<br/>
                - Монтаж фальцевой кровли основной<br/>
                - Монтаж кровли восьмигранной пирамиды из четырехгранной металлической чешуи.<br/>
                - Монтаж элементов карнизного свеса.<br/>
                <br/><br/>
                Кровельное покрытие: металлическая оцинкованная жесть<br/> с полимерным покрытием<br/>
                Вид кровли: четырехгранная металлическая чешуя<br/>
                Площадь - 100м2<br/>
                Срок выполнения - 1 месяц</>,
            images: [hrpsr1, hrpsr2]
        }

    return GetWork(workData)

};

export default Kaweli;