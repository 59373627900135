import * as React from 'react';
import * as styles from "./container.module.scss";
import cn from "classnames";

interface IContainerProps {
    children: React.ReactNode,
    className?: string
}

const Container: React.FC<IContainerProps> = ({children, className}) => {
    return (
        <div className={cn(styles.container, className)}>
            {children}
        </div>
    );
};

export default Container;