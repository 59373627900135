import * as React from 'react';
import * as styles from './mainBanner.module.scss';
import Container from "../../components/Container/Container";
import Button from "../../components/Button/Button";
import {useState} from "react";
import ModalOrderCall from "../../components/ModalOrderCall/ModalOrderCall";
import MyContext from "../../context/context";

export const MainBanner = () => {
    const [showModalOrder, setShowModalOrder] = useState(false)
    const {setShowModal} = React.useContext(MyContext);
    const onClickHandler = () => {
        setShowModalOrder(!showModalOrder)
        setShowModal(!showModalOrder)
    }
    return (
        <>
            <div className={styles.mainBanner}>
                <Container className={styles.container}>
                    <h1 className={styles.header}>КРОВЕЛЬНЫЕ РАБОТЫ <br/> САРАТОВ</h1>
                    <p className={styles.subHeader1}>Сварочные работы</p>
                    <p className={styles.subHeader2}>Любой сложности</p>
                    <Button text={'ОСТАВИТЬ ЗАЯВКУ'} className={styles.button} onClick={onClickHandler}/>
                </Container>

            </div>
            {showModalOrder && <ModalOrderCall onCloseModal={onClickHandler}/>}
        </>
    )
}