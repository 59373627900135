import * as styles from "./textField.module.scss";
import cn from "classnames";
import {Color} from "../../enums";
import React, {
    FC,
    forwardRef,
    DetailedHTMLProps,
    InputHTMLAttributes, ForwardedRef,
} from 'react';


interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    className: string,
    error: boolean,
    helperText: string,
    color: Color,
    multiline: boolean
}


// const TextField = React.forwardRef <HTMLInputElement, Partial<InputProps>>((
const TextField: FC<any> = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, Partial<InputProps>>((
    {
        ...props
    }
    , ref: ForwardedRef<HTMLInputElement | HTMLTextAreaElement> | null) => {

    const {color, className, error, helperText, multiline, ...rest} = props;

    return (
        <div className={styles.inputGroup}>

            {multiline ?
                <textarea
                    className={cn(styles.input, color === Color.SECONDARY && styles.dark, error && styles.error, className)}
                    ref={ref as ForwardedRef<HTMLTextAreaElement>}
                    {...rest}
                />
                :
                <input
                    ref={ref as ForwardedRef<HTMLInputElement>}
                    className={cn(styles.input, color === Color.SECONDARY && styles.dark, error && styles.error, className)}
                    {...rest}
                />}


            {error && <span>{helperText}</span>}
        </div>
    );
});

export default TextField;
