import * as React from 'react';
import GetWork from "../helpers";


function importAll(r) {
    // let images = {};
    let images = [];
    // console.log(r)
    r.keys().forEach((item, index) => {
        // console.log(r(item).default)
        images.push(r(item).default);
    });
    return images
}

const images = importAll(require.context('./', false, /\.(png|jpe?g|svg)$/));


const Abkhazia = () => {
    console.log(Object.values(images)[0].default)
    const workData =
        {
            header: <>
                Абхазия. Cело Псху. Церковь Усекновения<br/>
                Главы Иоанна Предтечи.</>,
            description: <>
                Виды работ:<br/>
                - Монтаж основной кровли<br/>
                - Изготовление боковых кокошников<br/>
                - Изготовление 8ми гранной пирамиды колокольни<br/>
                - Изготовление визайнтиской беседки над основным четверяком<br/>
                - Изготовление и монтаж 3-х главок с подкрестными шарами и крестами.<br/>
                - Изготовление и монтаж вручную главки и барабана на крестельню.<br/> (Высота главок: от 1.5м до
                2.7м)<br/>
                Монтаж вручную колоколов массой до 700кг.<br/>
                <br/>
                Тип кровли главок и купола: нержавейка с нитрит-титановым покрытием 0.5мм.<br/>
                Тип основной кровли: металлическая чешуя.<br/>
                Площадь: 800м2<br/>
                Срок выполнения выполнения вместе с монтажом без крана - 7 месяцев
            </>,
            images: images
        }

    return GetWork(workData)

};

export default Abkhazia;