import * as React from 'react';
import hibmshk1 from './hram-ikony-bozhiej-materi-sporitelnica-hlebov-krovlya_1.jpg'
import hibmshk2 from './hram-ikony-bozhiej-materi-sporitelnica-hlebov-krovlya_2.jpg'
import hibmshk3 from './hram-ikony-bozhiej-materi-sporitelnica-hlebov-krovlya_3.jpg'
import hibmshk4 from './hram-ikony-bozhiej-materi-sporitelnica-hlebov-krovlya_4.jpg'
import hibmshk5 from './hram-ikony-bozhiej-materi-sporitelnica-hlebov-krovlya_5.jpg'
import hibmshk6 from './hram-ikony-bozhiej-materi-sporitelnica-hlebov-krovlya_6.jpg'
import hibmshk7 from './hram-ikony-bozhiej-materi-sporitelnica-hlebov-krovlya_7.jpg'
import hibmshk8 from './hram-ikony-bozhiej-materi-sporitelnica-hlebov-krovlya_8.jpg'
import hibmshk9 from './hram-ikony-bozhiej-materi-sporitelnica-hlebov-krovlya_9.jpg'

import GetWork from "../helpers";

const Celinniy = () => {
    const workData =
        {
            header: <>Храм иконы Божией Матери
                «Спорительница хлебов»<br/>
                Посёлок Целинный, Саратовская область</>,
            description: <>Виды работы:<br/>
                - Монтаж сводчетых перекрытий основного четверика и пределов<br/>
                - Изготовление и монтаж 8ми гранной пирамиды основного <br/>четверика кровли алтаря и 3-х пределов<br/>
                - Изготовление и монтаж барабана, главки высотой 2,75см, подкрестного шара и креста.<br/>

                Кровельное покрытие: металлическая оцинкованная жесть <br/>с полимерным покрытием вид кровли: квадратная
                чешуя<br/>
                Площадь - 250м2<br/>
                Срок выполнения - 3 месяца</>,
            images: [hibmshk1, hibmshk2, hibmshk3, hibmshk4, hibmshk5, hibmshk6, hibmshk7, hibmshk8, hibmshk9]
        }

    return GetWork(workData)

};

export default Celinniy;