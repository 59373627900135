import * as React from 'react';
import * as styles from './stagesWork.module.scss';
import Container from "../../components/Container/Container";


export const StagesWork = () => {
    const stagesWork = [
        <>Заявка на сайте <br/>или звонок по телефону</>,
        <>Выезд мастера на замер<br/> Составление сметы</>,
        <>Подписание договора</>,
        <>Монтажные работы</>,
        <>Сдача объекта <br/> и оплата наших услуг</>,
    ]


    return (
        <section className={styles.stagesWork}>
            <div className={'h2'}> Этапы работы с нами</div>
            <Container className={styles.container}>
                <div className={styles.img}/>
                <div className={styles.wrapStages}>
                    {stagesWork.map((item, index) =>
                        <div className={styles.stage} key={index}>
                            <span className={styles.number}>{index + 1}</span>
                            <p className={styles.text}>{item}</p>
                        </div>)}
                </div>
            </Container>
        </section>
    )
}