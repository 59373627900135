type SendData = {
    phone: string,
    name?: string,
    message?: string
}

export function sendData({phone, name, message}: SendData) {

    const formData = new FormData();
    name && formData.append('name', name);
    message && formData.append('message', message);
    console.log(phone)
    formData.append('phone', phone);
    return fetch("https://krovsar.ru/send.php", {
        method: 'POST',
        mode: 'no-cors',
        body: formData
    })
}