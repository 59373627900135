import * as React from 'react';
import fix_roof from './fix_roof.jpg'
import mount_roof from './mount_roof.jpg'
import {GetAnswers} from "../helpers";

const Step1 = (props) => {
    const arrayAnswers = [
        {img: fix_roof, title: "ремонт кровли"},
        {img: mount_roof, title: "монтаж кровли"}
    ]
    const header = 'Выберите тип работы'

    return GetAnswers(header, arrayAnswers, props)

};

export default Step1;