import * as React from 'react';
import * as styles from '../quiz.module.scss';
import profnastil from './profnastil.jpg'
import metal_tile from './metal_tile.jpg'
import flex_tile from './flex_tile.jpg'
import folded_roof from './folded_roof.jpg'
import cn from 'classnames'
import {GetAnswers} from "../helpers";

const Step2 = (props) => {
    const arrayAnswers = [
        {img: metal_tile, title: "Металлочерепица"},
        {img: profnastil, title: "Профнастил"},
        {img: folded_roof, title: "Фальцевая кровля"},
        {img: flex_tile, title: "Гибкая черепица"},
    ]
    const header = 'Выберите материал кровли'

    return GetAnswers(header, arrayAnswers, props)
};

export default Step2;