import * as React from 'react';
import * as styles from "./thankYouMessage.module.scss";
import cn from "classnames";
import Button from "../Button/Button";

interface IThankYouMessage {
    onCloseModal: () => void;
}

const ThankYouMessage: React.FC<IThankYouMessage> = ({onCloseModal}) => {
    return (
        <div className={cn(styles.thankYouMessage)}>
            Спасибо! Мы вам перезвоним
            <Button text={'Закрыть'} onClick={onCloseModal}/>
        </div>
    );
};

export default ThankYouMessage;