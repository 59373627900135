import * as React from "react";
import * as styles from "./button.module.scss";
import cn from "classnames";
import {Color} from "../../enums";

interface IButtonProps {
    text?: string;
    className?: string;
    onClick?: (event?) => void;
    onKeyDown?: (event?) => void;
    value?: string;
    type?: string;
    size?: 'small' | 'medium' | 'large';
    color?: Color;
}

const Button: React.FC<IButtonProps> = ({
                                            text,
                                            className,
                                            onClick,
                                            onKeyDown,
                                            type,
                                            size,
                                            color = Color.DEFAULT,
                                            ...props
                                        }) => {
    const styleButton = () => {
        switch (size) {
            case 'medium':
                return styles.buttonMedium;
            case 'small':
                return styles.buttonSmall;
            default:
                return styles.button;
        }
    }

    return (
        <button
            className={cn(styleButton(), className, styles[color])}
            onClick={onClick}
            onKeyDown={onKeyDown}
            {...props}
        >
            {text}
        </button>
    );
};

export default Button;
