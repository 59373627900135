import * as React from 'react';
import * as styles from "./wrapModal.module.scss";
import cn from "classnames";

interface IWrapModalProps {
    children: React.ReactNode,
    className?: string,
    onClick: () => void
}

const WrapModal: React.FC<IWrapModalProps> = ({children, onClick, className}) => {
    return (
        <div className={cn(styles.wrapModal, className)} onClick={onClick}>
            {children}
        </div>
    );
};

export default WrapModal;