import * as React from "react";

const MyContext = React.createContext({
    showModal: false,
    setShowModal: (arg) => {
    },
    step: 1,
    setStep: () => {
    },
    answers: {},
    setAnswers: () => {
    }
});
// const MyContext = React.createContext('Default Value');


export default MyContext;